<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="門禁控制" btn="開啟門鎖" @edit="updateDevice()" />
    <!-- <BaseElForm label-width="200px" label-position="left">
      <BaseElFormItem label="門禁密碼鎖">
        {{ get(formData,'[0].password') }} <BaseElButton
          type="primary"
          class="copy-btn"
          @click="resetPassword()"
        >
          更新
        </BaseElButton>
      </BaseElFormItem>
    </BaseElForm> -->
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { ref, reactive, defineComponent, watch, onMounted } from 'vue'
import { get } from 'lodash'
export default defineComponent({
  name: 'DoorControl',
  components: {
    SectionTitle,
  },
  props: {
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    let formData = reactive({})
    const syncData = async () => {
      formData = props.data
    }
    const updateDevice = () => {
      emit('updateDevice', get(props, 'data[0].id'), true)
    }
    const resetPassword = () => {
      emit('resetPassword', get(props, 'data[0].id'))
    }
    watch(() => props.data, async () => {
      await syncData()
    })

    onMounted(async () => {
      await syncData()
    })

    return { formData, updateDevice, resetPassword, get }
  },
})
</script>
<style lang="postcss" scope>
.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
